import arni__marat from '../images/ambassadors/arni__marat.jpg'
import _elpachho from '../images/ambassadors/_elpachho.jpg'
import andokochkon from '../images/ambassadors/andokochkon.jpg'
import arkaymusic from '../images/ambassadors/arkay.music.jpg'
import badrimgu from '../images/ambassadors/badrimgu.jpg'
import chechenbro from '../images/ambassadors/chechen.bro.jpg'
import dkoryun from '../images/ambassadors/d.koryun.jpg'
import emmanuil123 from '../images/ambassadors/emmanuil123.jpg'
import erevanvartan from '../images/ambassadors/erevan.vartan.jpg'
import ivetti7 from '../images/ambassadors/ivetti7.jpg'
import kargayy from '../images/ambassadors/kargayy.jpg'
import mandarina_adelina from '../images/ambassadors/mandarina_adelina.jpg'
import markovvanv from '../images/ambassadors/markovvanv.jpg'
import mellikson from '../images/ambassadors/mellikson.jpg'
import ripsigal from '../images/ambassadors/ripsigal.jpg'
import saakmkrtchyan from '../images/ambassadors/saakmkrtchyan.jpg'
import sergo_artemka from '../images/ambassadors/sergo_artemka.jpg'
import shushannabest from '../images/ambassadors/shushannabest.jpg'
import sofabro_life from '../images/ambassadors/sofabro_life.jpg'
import sofiyabroyan from '../images/ambassadors/sofiyabroyan.jpg'
import sonyaoffi from '../images/ambassadors/sonyaoffi.jpg'
import vaagmk from '../images/ambassadors/vaagmk.jpg'
import wallley from '../images/ambassadors/walll.ey.jpg'



const dataAmbassadors = [
    {
        id: 1,
        img : arni__marat,
        name: 'arni__marat'
    },
    {
        id: 2,
        img : _elpachho,
        name: '_elpachho'
    },
    {
        id: 3,
        img : andokochkon,
        name: 'andokochkon'
    },
    {
        id: 4,
        img : arkaymusic,
        name: 'arkay.music'
    },
    {
        id: 5,
        img : badrimgu,
        name: 'badrimgu'
    },
    {
        id: 6,
        img : chechenbro,
        name: 'chechen.bro'
    },
    {
        id: 7,
        img : dkoryun,
        name: 'd.koryun'
    },
    {
        id: 8,
        img : emmanuil123,
        name: 'emmanuil123'
    },
    {
        id: 9,
        img : erevanvartan,
        name: 'erevan.vartan'
    },
    {
        id: 10,
        img : ivetti7,
        name: 'ivetti7'
    },
    {
        id: 11,
        img : kargayy,
        name: 'kargayy'
    },
    {
        id: 12,
        img : mandarina_adelina,
        name: 'mandarina_adelina'
    },
    {
        id: 13,
        img : markovvanv,
        name: 'markovvanv'
    },
    {
        id: 14,
        img : mellikson,
        name: 'mellikson'
    },
    {
        id: 15,
        img : ripsigal,
        name: 'ripsigal'
    },
    {
        id: 16,
        img : saakmkrtchyan,
        name: 'saakmkrtchyan'
    },
        {
        id: 17,
        img : shushannabest,
        name: 'shushannabest'
    },
    {
        id: 18,
        img : sofabro_life,
        name: 'sofabro_life'
    },
    {
        id: 19,
        img : sofiyabroyan,
        name: 'sofiyabroyan'
    },
    {
        id: 20,
        img : sonyaoffi,
        name: 'sonyaoffi'
    },
    {
        id: 21,
        img : vaagmk,
        name: 'vaagmk'
    },
    {
        id: 22,
        img : wallley,
        name: 'walll.eyc'
    }
]
export default dataAmbassadors;