const dataAbout = [
    {
        id: 1,
        title: 'PVP battles & Land grabs',
        title_ru: 'PVP сражения и захват земель',
        desc: 'Raids of unfriendly factions, and raids on lands captured by other players. One of the unique systems is the mining of early-home cryptocurrency on captured or purchased land. ',
        desc_ru: 'Рейды недружественных фракций и рейды на земли, захваченные другими игроками. Одна из уникальных систем - добыча случайной криптовалюты на захваченных или купленных землях.',
    },
    {
        id: 2,
        title: 'Trading',
        title_ru: 'Торговля',
        desc: 'In the CyberOmens metaverse, you can also carry out you’re trading on such large exchanges as Binance, OKX, ByBiT, Kucoin without leaving the game. For those who like to try their best, there will also be Casino and offices of betting companies, in which you can bet on your favorite team, athlete or player who will conduct the fight in the arena.',
        desc_ru: 'В метавселенной CyberOmens вы также сможете проводить свои торговые операции на таких крупных биржах, как Binance, OKX, ByBiT, Kucoin, не выходя из игры. Для любителей помериться силами также будут работать казино и офисы букмекерских контор, в которых вы сможете сделать ставку на любимую команду, спортсмена или игрока, который будет вести бой на арене.'
    },
    {
        id: 3,
        title: 'Mediaspace',
        title_ru: 'Медиасфера',
        desc: 'Media space will be available, cinemas will be available to watch movie premieres. Rooms for general meetings, entertainment and communication between players. Halls for concerts and broadcasts of performances. For music lovers there will be clubs where they can relax listening to new tracks by their favorite artists.',
        desc_ru: 'Будут доступны медиа-пространство, кинотеатры для просмотра кинопремьер. Залы для общих собраний, развлечений и общения между игроками. Залы для проведения концертов и трансляций выступлений. Для любителей музыки будут работать клубы, где они смогут отдохнуть, слушая новые треки любимых исполнителей.'
    },
]

export default dataAbout;