import React, { useState } from "react";
import Button from "../components/button";
import useLocalStorage from "../hooks/use-localstorage";
import { Link } from "react-router-dom";

function Page404(props) {
  const [language] = useLocalStorage("language", "ru");

  return (
    <section className="page-title p404">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="breadcrumbs center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h1>404</h1>
              <h2>
                {" "}
                {language === "ru" ? "Страница не найдена" : "Page Not Found"}
              </h2>
              <p>
                {language === "ru"
                  ? "Мы не можем найти страницу, которую вы ищете"
                  : "We can’t find the page that you’re looking for"}
                .
              </p>
              <Link className="tf-button btn-effect" to="/">
                <span className="boder-fade"></span>
                <span className="effect">
                  {" "}
                  {language === "ru"
                    ? "Вернутся на главную"
                    : "Back To Homepage"}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Page404;
