const dataFaq = [
  {
    id: 1,
    title: "How to play?",
    title_ru: "Как играть?",
    content_ru:
      "Для того что бы начать играть, вам понадобится аватар. Аватар вы сможете получить как вознаграждение, выполняя задания по развитию социальных сетей, так и участвуя в airdrop. Так же вы сможете его приобрести в NFT маркетплейсе.",
    content:
      "To start playing, you need an avatar. Avatar you can get as a reward by performing tasks on the development of social networks, and participating in airdrop. Just as you can buy it in the NFT marketplace",
    show: "show",
  },
  {
    id: 2,
    title: "What is Avatar?",
    title_ru: "Что такое Аватар?",
    content_ru:
      "Аватар это игровой персонаж, который вам понадобится для игры и коммуникации в метавселенной CyberOmens.",
    content:
      "Avatar is a game character that you will need to play and communicate in the CyberOmens meta universe.",
  },
  {
    id: 3,
    title: "Assets and land?",
    title_ru: "Активы и земли?",
    content_ru:
      "В метавселенной CyberOmens, вы сможете захватывать нейтральные земли и в последствие монетизировать их. На них можно развернуть добычу токенов или же построить целый город. Выбор только за вами",
    content:
      "In meta universe CyberOmens, you can capture neutral lands and then monetize them. You can deploy token mining on them or build an entire city. The choice is up to you.",
  },
  {
    id: 4,
    title: "PVP battles, how can I make money on it?",
    title_ru: "PVP сражения, как я могу на этом заработать?",
    content_ru:
      "Для любителей активного PVP мы предусмотрели систему боевки 1v1, систему олимпиады.\n" +
      "\n" +
      "Сражение 1v1 игроки делают ставки токенами CyberOmens, победитель забирает токены проигравшего.\n" +
      "\n" +
      "Система олимпиады, данное мероприятие будет проводится  раз в месяц и будет идти 3 дня.\n" +
      "\n" +
      "Игроки регистрируются для участие в олимпиаде (регистрация бесплатная). Старт олимпиады будет каждый день в определенное время, где игроки за победой на другим участником будут получать очки по окончанию олимпиады. Победителем будет тот игрок, который набрал большее количество очков.",
    content:
      "For fans of active PVP we have provided a system of combat 1v1, the system of the Olympics.\n" +
      "\n" +
      "1v1 battle players bet with CyberOmens tokens, the winner takes the tokens of the loser.\n" +
      "\n" +
      "Olympiad system, this event will be held once a month and will run for 3 days.\n" +
      "\n" +
      "Players register for the Olympiad (registration is free). Start of the Olympiad will be every day at a certain time, where players will receive points at the end of the Olympiad for winning over another participant. The winner will be the player with the most points.\n",
  },
];

export default dataFaq;
