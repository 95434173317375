import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import FoundationsItem from './foundations-item';
import Button from "../../components/button";
import useLocalStorage from "../../hooks/use-localstorage";

Foundations.propTypes = {
    data: PropTypes.array,
};

function Foundations(props) {
    const [language] = useLocalStorage('language', 'ru');
    const {data} = props;

    const [dataBlock] = useState({
        subtitle: 'Foundations',
        subtitle_ru: 'Фонды',
        title: 'Investors Foundation',
        title_ru: 'Инвестиционные фонды',
        desc: ''
    })
    return (
        <section className="tf-section how-we-work">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title st2 mb-60" data-aos="fade-up" data-aos-duration="800">
                            <p className="h8 sub-title text-center">{language === 'ru' ? dataBlock.subtitle_ru : dataBlock.subtitle}</p>
                            <h4 className="title m-b17 text-center">{language === 'ru' ? dataBlock.title_ru : dataBlock.title}</h4>
                        </div>
                    
                    </div>

                    {
                        data.map(item => (
                            <div key={item.id} className="col-xl-3 col-lg-3 col-md-6 col-6" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
                                <FoundationsItem item={item} />
                            </div>
                        ))
                    }
                    <div className="flex col-md-12">
                        <div className="mx-auto mt-5">
                        <Button title={language === 'ru' ? 'Оставить заявку' : 'Send request'} path='/fonds' />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Foundations;