import { React, useState } from "react";
import PageTitle from "../components/pagetitle";
import { pushForm } from "../http/fondAPI";
import useLocalStorage from "../hooks/use-localstorage";
import dataAbout from "../assets/fake-data/data-about";
import About from "../features/about";

function Contact(props) {
  const [language] = useLocalStorage("language", "ru");
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [telegram, setTelegram] = useState();
  const [msgerror, setMsgerror] = useState();
  const [hideform, setHideform] = useState(true);
  const [msgsuccess, setMsgsuccess] = useState();
  const send = async (event) => {
    try {
      event.preventDefault();
      const response = await pushForm(name, email, telegram);
      console.log(response.statusText);
      if (response.statusText === "Created") {
        setHideform(false);
        setMsgerror("");
        setMsgsuccess(
          language === "ru"
            ? "Ваша заявка отправлена!"
            : "Your request was send"
        );
      } else {
        setMsgerror("Ошибка, попробуйте позже");
      }
    } catch (e) {
      setMsgerror(e.response.data.message);
    }
  };

  return (
    <div>
      <PageTitle title="" />

      <section className="tf-section tf-contact">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12 mx-auto">
              <div
                className="content-about m-b50 mobie-40"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div className="tf-title st2 m-b17">
                  <h4 className="title">
                    {language === "ru"
                      ? "Форма для фондов"
                      : "Fonds form"}
                  </h4>
                </div>
                <p className="m-r-40">
                  {language === "ru"
                    ? "Отправляйте заявку и мы свяжемся с вами"
                    : "Send request and we write you"}
                </p>
              </div>
              <div className="text-red-800 text-xl font-extrabold mb-5">
                {msgerror}
              </div>
              <div className="text-purple-500 text-3xl font-extrabold mb-5">
                {msgsuccess}
              </div>
              {hideform ? (
                <form
                  className="form-contact"
                  id="contactform"
                  data-aos="fade-right"
                  data-aos-duration="800"
                  onSubmit={send}
                >
                  <fieldset>
                    <input
                      type="text"
                      name="name"
                      required={true}
                      id="name"
                      placeholder={language === "ru" ? "Ваше имя" : "Name"}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </fieldset>
                  <fieldset>
                    <input
                      type="text"
                      name="telegram"
                      id="telegram"
                      required={true}
                      placeholder="Telegram"
                      value={telegram}
                      onChange={(e) => setTelegram(e.target.value)}
                    />
                  </fieldset>
                  <fieldset>
                    <input
                      type="email"
                      required={true}
                      name="email"
                      id="email"
                      placeholder={
                        language === "ru"
                          ? "Адрес электронной почты"
                          : "Email Address"
                      }
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </fieldset>
                  <button className="tf-button btn-effect" type="submit">
                    <span className="boder-fade"></span>
                    <span className="effect">
                      {language === "ru" ? "Отправить" : "Send"}
                    </span>
                  </button>
                </form>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
