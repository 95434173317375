import React, { useState } from "react";
import useLocalStorage from "../hooks/use-localstorage";
import PageTitle from "../components/pagetitle";
import img_abc from "../assets/images/abc.png";
function Community(props) {
  const [language] = useLocalStorage("language", "ru");
  return (
    <div className="community">
      <PageTitle title="" />

      <section className="tf-section tf-video">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="content-about mb-51 mobie-40">
                <div
                  className="tf-title pd22"
                  data-aos="fade-up"
                  data-aos-duration="800"
                >
                  <h3 className="title">
                    {" "}
                    {language === "ru"
                      ? "Инструкция по приобритению CYBER"
                      : "How buy CYBER"}
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-6 col-xs-6"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <div className="flex h-full">
                  <span className="m-auto text-2xl">
                    {language === "ru"
                      ? "Для того что бы оплатить токены проекта вам понадобится скачать кошелек Metamask (скачать его можно в вашем маркете AppStore, Play Market). Перед регистрацией посмотрите это видео"
                      : "In order to pay for the tokens of the project you need to download Metamask wallet (you can download it in your market AppStore, Play Market). Before registering, watch this video"}
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-xs-6 ">
                <div className="video">
                  <div
                    className="video"
                    style={{
                      position: "relative",
                      paddingBottom: "56.25%" /* 16:9 */,
                      paddingTop: 25,
                      height: 0,
                    }}
                  >
                    <iframe
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                      src={`https://www.youtube.com/embed/dtPG9SRKQw8`}
                      frameBorder="0"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row mt-5"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="col-md-12 text-center text-2xl">
                {language === "ru"
                  ? "Далее вам нужно перейти на обменник для пополнения своего кошелька в USDT по "
                  : "Then you need to go to the exchanger to deposit your wallet in USDT by "}
                <a
                  href="https://abcobmen.com/?ref=16672034087249"
                  target="_blank"
                >
                  <b>{language === "ru" ? "ссылке" : "link"}</b>
                </a>
                <br />
                {language === "ru"
                  ? 'В поле "Получаю" вставить адрес вашего кошелька Metamask.'
                  : 'In the "Receive" field, insert the address of your Metamask Wallet.'}
                <b>
                  {" "}
                  {language === "ru"
                    ? "Обратите внимание, сеть должна быть Binance Smart Chain"
                    : "Note, the network must be Binance Smart Chain"}
                </b>
                <img src={img_abc} alt="" className="m-auto py-5" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Community;
