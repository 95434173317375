import { $host } from "./index";

export const pushForm = async (name, email, telegram, amount) => {
  const response = await $host.post("api/fondmailer/", {
    name,
    email,
    telegram,
  });
  return response;
};
