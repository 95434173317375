import { ResponsivePieCanvas } from "@nivo/pie";
import React from "react";
import "./styles.scss";

const data = [
  {
    id: "Team",
    color: "hsl(187, 100%, 59%)",
    label: "Team",
    value: 15,
  },
  { id: "User Inactive", label: "User Inactive", value: 2, color: "#E95EF0" },
  { id: "Reserved", label: "Reserved", value: 20, color: "#65ECF6" },
  {
    id: "Community & Marketing",
    label: "Community & Marketing",
    value: 15,
    color: "#FC436F",
  },
  { id: "Seed Round", label: "Seed Round", value: 5, color: "#BAE6E5" },
  { id: "PreSale", label: "PreSale", value: 18, color: "#B090F9" },
  { id: "Public Sale", label: "Public Sale", value: 6, color: "#A9931A" },
  {
    id: "Ecosystem CyberOmens",
    label: "Ecosystem CyberOmens",
    value: 10,
    color: "#FC436F",
  },
  {
    id: "Ambassadors Reward",
    label: "Ambassadors Reward",
    value: 2,
    color: "#E95EF0",
  },
  { id: "Staking", label: "Staking", value: 7, color: "#FFC311" },
];

const theme = {
  axis: {
    fontSize: "20px",
    tickColor: "#eee",
    ticks: {
      line: {
        stroke: "#555555",
      },
      text: {
        fill: "#000",
      },
    },
    legend: {
      text: {
        fill: "#aaaaaa",
      },
    },
  },
  grid: {
    line: {
      stroke: "#000",
    },
  },
};

function Tokenomics() {
  return (
    <section className="tf-section partner">
      <div id="nomics" style={{ marginBottom: "120px" }}></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="tf-title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <p className="h8 sub-title text-4xl">Tokenomics</p>
            </div>
          </div>
          <div className="row" style={{ margin: 0, padding: 0 }}>
            <div className="col-md-12" style={{ margin: 0, padding: "0 10px" }}>
              <div className="justify-center content-center mx-auto pieblock ">
                <ResponsivePieCanvas
                  data={data}
                  margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
                  pixelRatio={1}
                  innerRadius={0.6}
                  padAngle={0.7}
                  cornerRadius={3}
                  animate={true}
                  tooltip={({ datum: { id, value, color } }) => (
                    <div
                      style={{
                        padding: 12,
                        color,
                        background: "#222222",
                      }}
                    >
                      <span>{id}</span>
                      <br />
                      <strong>{value} %</strong>
                    </div>
                  )}
                  valueFormat={(value) =>
                    `${Number(value).toLocaleString("ru-RU", {
                      minimumFractionDigits: 0,
                    })} %`
                  }
                  activeOuterRadiusOffset={10}
                  activeInnerRadiusOffset={4}
                  colors={[
                    "hsl(187, 100%, 59%)",
                    "#E95EF0",
                    "#65ECF6",
                    "#FC436F",
                    "#BAE6E5",
                    "#B090F9",
                    "#A9931A",
                    "#FC436F",
                    "#E95EF0",
                    "#FFC311",
                  ]}
                  colorBy="index"
                  theme={theme}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.6]],
                  }}
                  enableArcLinkLabels={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Tokenomics;
