import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import Button from "../../components/button";
import AboutItem from "./about-item";
import bgImg from "../../assets/images/background/bg-2nd.png";
import useLocalStorage from "../../hooks/use-localstorage";

About.propTypes = {
  data: PropTypes.array,
};

function About(props) {
  const [language] = useLocalStorage("language", "ru");
  const { data } = props;

  const [dataBlock] = useState({
    subtitle: "About us",
    subtitle_ru: "О проекте",
    title: "What is CyberOmens?",
    title_ru: "Что такое CyberOmens",
    desc: "Explore a user-owned meta universe through virtual reality. CyberOmens will contain social, gaming, and cryptocurrency elements so that everyone can find a way to have fun in the meta universe.",
    desc_ru:
      "Исследуйте принадлежащую пользователю мета-вселенную через виртуальную реальность. CyberOmens будет содержать социальные, игровые и криптовалютные элементы, чтобы каждый мог найти способ развлечься в мета-вселенной.",
  });

  return (
    <section className="tf-section tf-about bg-second">
      <div id="about" style={{ marginBottom: "120px" }}></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-md-12">
            <div
              className="content-about mobie-40"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="tf-title st2">
                <p className="h8 sub-title">
                  {language === "ru"
                    ? dataBlock.subtitle_ru
                    : dataBlock.subtitle}
                </p>
                <h4 className="md:mt-20 title">
                  {language === "ru" ? dataBlock.title_ru : dataBlock.title}
                </h4>
              </div>
              <p>{language === "ru" ? dataBlock.desc_ru : dataBlock.desc}</p>
              {/*<Button title={language === 'ru' ? 'Изучить' : 'Look Up'} path='/' />*/}
            </div>
          </div>
          <div className="col-xl-7 col-md-12">
            <div
              className="wrap-about"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              {data.map((item) => (
                <AboutItem key={item.id} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
