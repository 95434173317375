import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import ChainsItem from './chains-item';
import useLocalStorage from "../../hooks/use-localstorage";

Chains.propTypes = {
    data: PropTypes.array,
};

function Chains(props) {
    const [language] = useLocalStorage('language', 'ru');
    const {data} = props;

    const [dataBlock] = useState({
        subtitle: 'Integration',
        subtitle_ru: 'Интеграция',
        title: 'Chain integrated',
        title_ru: 'Поддерживаемые сети',

    })
    return (
        <section className="tf-section how-we-work">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title st2 mb-60" data-aos="fade-up" data-aos-duration="800">
                            <p className="h8 sub-title text-center">{language === 'ru' ? dataBlock.subtitle_ru : dataBlock.subtitle}</p>
                            <h4 className="title m-b17 text-center">{language === 'ru' ? dataBlock.title_ru : dataBlock.title}</h4>
                        </div>
                    
                    </div>

                    {
                        data.map(item => (
                            <div key={item.id} className="col-xl-4 col-lg-4 col-md-12 col-12" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
                                <ChainsItem item={item} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default Chains;