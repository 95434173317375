import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import AmbassadorsItem from './ambassadors-item';
import useLocalStorage from "../../hooks/use-localstorage";

Ambassadors.propTypes = {
    data: PropTypes.array,
};

function Ambassadors(props) {
    const [language] = useLocalStorage('language', 'ru');
    const {data} = props;

    const [dataBlock] = useState({
        subtitle: 'Social media',
        subtitle_ru: 'Социальные сети',
        title: 'AMBASSADORS',
        title_ru: 'Амбассадоры',
        desc: 'CyberOmens media support in social networks',
        desc_ru: 'Медиаподдержка CyberOmens в социальных сетях',
    })
    return (
        <section className="tf-section how-we-work">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title st2 mb-60" data-aos="fade-up" data-aos-duration="800">
                            <p className="h8 sub-title text-center">{language === 'ru' ? dataBlock.subtitle_ru : dataBlock.subtitle}</p>
                            <h4 className="title m-b17 text-center">{language === 'ru' ? dataBlock.title_ru : dataBlock.title}</h4>
                            <p className="text-center">{language === 'ru' ? dataBlock.desc_ru : dataBlock.desc}</p>
                        </div>
                    
                    </div>

                    {
                        data.map(item => (
                            <div key={item.id} className="col-xl-2 col-lg-3 col-md-4 col-4" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
                                <AmbassadorsItem item={item} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default Ambassadors;