import imgFound from '../images/foundations/found.png'




const dataFoundations = [
    {
        id: 1,
        img : imgFound,
        name: 'Foundations'
    },
    {
        id: 2,
        img : imgFound,
        name: 'Foundations'
    },
    {
        id: 3,
        img : imgFound,
        name: 'Foundations'
    },
    {
        id: 4,
        img : imgFound,
        name: 'Foundations'
    }
]
export default dataFoundations;