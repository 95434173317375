const dataRoadmap = [
    {
        id: 1,
        time: 'Q3 2022',
        list: [
            {
                text: 'Project Launch',
                text_ru: 'Запуск проекта',
            },
            {
                text: 'Launch of the site',
                text_ru: 'Запуск сайта',
            },
            {
                text: 'Attracting media support',
                text_ru: 'Привлечение медиа поддержки',
            },
            {
                text: 'Create social networks',
                text_ru: 'Создание социальных сетей',
            },
            {
                text: 'Launch of SEED round for investment funds',
                text_ru: 'Запуск SEED раунда для инвестиционных фондов',
            },
            {
                text: 'Holding AMA sessions with company representatives',
                text_ru: 'Проведение АМА сессий с представителями компаний',
            }

        ],
        positon: 'left',
        style: 'normal'
    },
    {
        id: 2,
        time: 'Q4 2022',
        list: [
            {
                text: 'Launch a private round for media ambassadors',
                text_ru: 'Проведение частного раунда для медиа амбассадоров',
            },
            {
                text: 'Gameplay development and visualization',
                text_ru: 'Проработка игрового процесса и визуализаций',
            },
            {
                text: 'NFT Marketplace development',
                text_ru: 'Разработка NFT маркетплейса',
            },
            {
                text: 'Launch Avatar, Constructures, Inventory sales',
                text_ru: 'Запуск продажи Аватаров, Зданий, Инвентаря',
            },
            {
                text: 'Submission of applications to stock exchanges for presale',
                text_ru: 'Подача заявок на биржи для проведения предпродажи',
            },
            {
                text: 'Launch public sale',
                text_ru: 'Запуск публичной продажи',
            },
        ],
        positon: 'right',
        style: 'normal'
    },
    {
        id: 3,
        time: 'Q1 2023',
        list: [
            {
                text: 'Launching an alpha test of the game',
                text_ru: 'Запуск альфа теста игры',
            },
            {
                text: 'Selecting the testing team',
                text_ru: 'Отбор команды тестировщиков',
            },
            {
                text: 'Binding media platforms',
                text_ru: 'Привязка медиа платформ',
            },
            {
                text: 'Launch of NFT marketplace',
                text_ru: 'Запуск маркетплейса NFT',
            },
            {
                text: 'Introduction of neutral lands map and PVP mechanics',
                text_ru: 'Представление карты нейтральных земель и PVP механик',
            },
            {
                text: 'Introducing the Olympics system',
                text_ru: '',
            },
            {
                text: 'Selling fixed lands to build their cities',
                text_ru: 'Представление системы Олимпиады',
            },
            {
                text: 'Introduction of neutral lands for capture and token mining list',
                text_ru: 'Введение списка нейтральных земель для захвата и добычи токенов',
            },
            {
                text: 'Introduction of neutral lands siege mode, clan and alliance system',
                text_ru: 'Введение режима осады нейтральных земель, системы кланов и альянсов',
            },
            {
                text: 'Presentation of token crafting table, table of rarity and chance of crafting a certain token',
                text_ru: 'Представление таблицы крафтинга токенов, таблицы редкости и шанса крафтинга определенного токена',
            },

        ],
        positon: 'left'
    },
    {
        id: 4,
        time: 'Q2 2023',
        list: [
            {
                text: 'Writing a report on the alpha test',
                text_ru: 'Составление отчета о проведение альфа теста',
            },
            {
                text: 'Rewarding participants who participated in the alpha test',
                text_ru: 'Награждение участников принявшие участие в альфа тесте',
            },
            {
                text: 'Binding certain exchanges (partners) to the buildings in the initial city',
                text_ru: 'Привязка определенных бирж (партнеров) к строениям в начальном городе',
            },
            {
                text: 'Implementation of the trade and communication with the exchanges in the game',
                text_ru: 'Реализация режима торговли и связи с биржами внутри игры',
            },
            {
                text: 'Launch the first version of the beta test',
                text_ru: 'Запуск первой версии бета теста',
            },
            {
                text: 'Integrating blockchain into the game',
                text_ru: 'Интеграция блокчейна в игру',
            },
            {
                text: 'Adapting beta version of the game for mobile devices',
                text_ru: 'Адаптация бета версии игры для мобильных устройств',
            },
            {
                text: 'Collection of reports, bug fixes in the beta version of the game',
                text_ru: 'Сбор отчетов, исправление ошибок в бета версии игры',
            },
        ],
        positon: 'right'
    },
    {
        id: 5,
        time: 'Q3 2023',
        list: [
            {
                text: 'Launching the PC version of the game',
                text_ru: 'Запуск версии для настольных компьютеров',
            },
            {
                text: 'Launch of mobile version of the game',
                text_ru: 'Запуск мобильной версии игры',
            },
            {
                text: 'Launch of full-scale worldwide marketing',
                text_ru: 'Запуск полномасштабной маркетинкого компани',
            },
            {
                text: 'Attracting media stars for the game\'s social community',
                text_ru: 'Привлечение медиа звезд для социального комьюнити в игре',
            },
            {
                text: 'To the moon...',
                text_ru: 'To the moon...',
            },
        ],
        positon: 'left'
    },

]

export default dataRoadmap;