import React, { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";
import menus from "../../pages/menu";
import { HashLink as Link } from "react-router-hash-link";

import useLocalStorage from "./../../hooks/use-localstorage";
import i18n from "./../../i18n";

import "./styles.scss";
import logo from "../../assets/images/logo/logo.png";

const Header = () => {
  const [language, setLanguage] = useLocalStorage("language", "ru");

  const handleLenguageChange = () => {
    if (language === "en") {
      i18n.changeLanguage("ru");
      setLanguage("ru");
      window.location.reload();
    } else if (language === "ru") {
      i18n.changeLanguage("en");
      setLanguage("en");
      window.location.reload();
    }
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 300);
    });
    return () => {
      setScroll({});
    };
  }, []);

  const [menuActive, setMenuActive] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex(index);
  };

  return (
    <header id="header_main" className={`header ${scroll ? "is-fixed" : ""}`}>
      <div className="container">
        <div id="site-header-inner">
          <div className="header__logo">
            <NavLink to="/">
              <img src={logo} alt="CyberOmens" />
            </NavLink>
          </div>
          <nav
            id="main-nav"
            className={`main-nav ${menuActive ? "active" : ""}`}
          >
            <ul id="menu-primary-menu" className="menu">
              {menus.map((data, idx) => (
                <li
                  key={idx}
                  onClick={() => handleDropdown(idx)}
                  className={`menu-item ${
                    data.namesub ? "menu-item-has-children" : ""
                  } ${activeIndex === idx ? "active" : ""}`}
                >
                  <Link to={data.links}>
                    {language === "ru" ? data.name_ru : data.name}
                  </Link>
                  {data.namesub && (
                    <ul className="sub-menu">
                      {data.namesub.map((submenu) => (
                        <li key={submenu.id} className="menu-item">
                          <NavLink to={submenu.links}>
                            {language === "ru" ? submenu.name_ru : submenu.name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <a
            className="tf-button btn-effect"
            href={"https://cyberomens.t.me"}
            target="_blank"
            rel="noreferrer"
          >
            <span className="boder-fade"></span>
            <span className="effect">
              {language === "ru" ? "telegram" : "telegram"}
            </span>
          </a>
          <button
            className="tf-button-st3 btn-effect lng-btn"
            onClick={handleLenguageChange}
          >
            {language === "ru" ? "en" : "ru"}
          </button>

          <div
            className={`mobile-button ${menuActive ? "active" : ""}`}
            onClick={handleMenuActive}
          >
            <span></span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
