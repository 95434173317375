import React from 'react';
import PropTypes from 'prop-types';
import useLocalStorage from "../../../hooks/use-localstorage";

AboutItem.propTypes = {
    item: PropTypes.object,
};

function AboutItem(props) {
    const [language] = useLocalStorage('language', 'ru');
    const {item} = props;

    return (
        <div key={item.id} className={`box-text corner-box rounded-lg ${item.class}`}>
            <div className="h7">{language === 'ru' ? item.title_ru : item.title}</div>
            <p>{language === 'ru' ? item.desc_ru : item.desc}</p>
        </div>
    );
}

export default AboutItem;