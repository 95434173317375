import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap-accordion';
import useLocalStorage from "../../../hooks/use-localstorage";

AccordionItem.propTypes = {
    item: PropTypes.object,
};

function AccordionItem(props) {
    const {item} = props;
    const [language] = useLocalStorage('language', 'ru');

    return (
        <Accordion key={item.id} title={language === 'ru' ? item.title_ru : item.title} show={item.show}>
            <p className="toggle-content">{language === 'ru' ? item.content_ru : item.content}</p>
        </Accordion>
    );
}

export default AccordionItem;