import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

AmbassadorsItem.propTypes = {
  item: PropTypes.object,  
};

function AmbassadorsItem(props) {

    const {item} = props;

    return (
        <div className={``}>
            <a className="" href={"https://instagram.com/" + item.name} target="_blank">
                <img
                    src={item.img}
                    alt={item.name}
                    className="rounded-full  corner-box-ambassadors"
                />
            </a>
        </div>
    );
}

export default AmbassadorsItem;