const menus = [
    {
        id: 1,
        name: 'Home',
        name_ru: 'Главная',
        links: '/#main',

    },
    {
        id: 2,
        name: 'About',
        name_ru: 'Проект',
        links: '/#about',
    },
    {
        id: 3,
        name: 'Road Map',
        name_ru: 'Road Map',
        links: '/#roadmap',

    },
    {
        id: 4,
        name: 'WhitePaper',
        name_ru: 'Whitepaper',
        links: '#',

    },
    {
        id: 5,
        name: 'faq',
        name_ru: 'faq',
        links: '/#faq',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Our Blog',
        //         name_ru: 'Наш блог',
        //         links: '#'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Blog Details',
        //         name_ru: 'Детали',
        //         links: '#'
        //     },
        // ],
    },
    {
        id: 6,
        name: 'Nomics',
        name_ru: 'Nomics',
        links: '/#nomics'
    },
    
]

export default menus;