import imgBsc from '../images/chains/bsc_logo.png'
import imgEth from '../images/chains/eth_logo.png'
import imgPoly from '../images/chains/polygon_logo.png'
import imgHar from '../images/chains/one_logo.png'
import imgSol from '../images/chains/solana-logo.png'
import imgAval from '../images/chains/avax_logo.png'




const dataChains = [
    {
        id: 1,
        img : imgBsc,
        name: 'Binance Smart Chain'
    },
    {
        id: 2,
        img : imgEth,
        name: 'Etherium'
    },
    {
        id: 3,
        img : imgPoly,
        name: 'Polygon'
    },
    {
        id: 4,
        img : imgHar,
        name: 'Harmony'
    },
    {
        id: 5,
        img : imgSol,
        name: 'Solana'
    },
    {
        id: 6,
        img : imgAval,
        name: 'Avalanche'
    }
]
export default dataChains;