import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

ChainsItem.propTypes = {
  item: PropTypes.object,  
};

function ChainsItem(props) {

    const {item} = props;

    return (
        <div className="flex">
            <div className="mx-auto my-3">
                <img
                    src={item.img}
                    alt={item.name}

                />
            </div>
        </div>
    );
}

export default ChainsItem;