import bgImg1 from '../images/background/bg-2.jpg';
import img from '../images/robo.png';

const dataSlider = [
    {
        id: 1,
        title: 'CyberOmens',
        desc : 'This is a unique meta-universe that integrates not only gameplay, but also the economic component and media space.\n',
        desc_ru: "Это уникальная метавселенная, которая объединяет не только геймплей, но и экономическую составляющую и медиапространство.",
        bgImg: bgImg1,
        img : img
    },
    // {
    //     id: 2,
    //     title: 'CyberOmens for everyone',
    //     desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
    //     bgImg: bgImg2,
    //     classAction: 'two'
    // },
    // {
    //     id: 3,
    //     title: 'CyberOmens for everyone',
    //     desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
    //     bgImg: bgImg3,
    //     classAction: 'three'
    // },
    // {
    //     id: 4,
    //     title: 'CYbox nft collectionS for everyone',
    //     desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
    //     bgImg: bgImg1,
    //     img : img1
    // },
    // {
    //     id: 5,
    //     title: 'CYbox nft collectionS for everyone',
    //     desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
    //     bgImg: bgImg2,
    //     classAction: 'two'
    // },

]

export default dataSlider;